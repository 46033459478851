import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsAccessControlDirective } from './directives/cms-access-control.directive';
import { LoaderComponent } from './../loader/loader.component';
import { NgxLoadingModule } from 'ngx-loading';
import { AddSignatureComponent } from './add-signature/add-signature.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ContentSanitizerPipe } from './pipes/content-sanitizer.pipe';
import { RemoveUserFormListPipe } from './pipes/remove-user-form-list.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AskAiComponent } from './ask-ai/ask-ai.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';


@NgModule({
  declarations: [
    CmsAccessControlDirective,
    LoaderComponent,
    AddSignatureComponent,
    ContentSanitizerPipe,
    RemoveUserFormListPipe,
    AskAiComponent,
    CopyClipboardDirective
  ],
  imports: [
  CommonModule,FormsModule,
  SignaturePadModule,
  NgbModule,
  NgxLoadingModule.forRoot({}),
  ],
  exports:[CmsAccessControlDirective,LoaderComponent,AddSignatureComponent,ContentSanitizerPipe, RemoveUserFormListPipe,AskAiComponent,
    CopyClipboardDirective
  ]
})
export class GlobalSharedModule { }
